import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { format } from "date-fns"
import queryString from "query-string"
import BirthdayPicker from "./components/birthday-picker"
import Spinner from "./components/spinner"
import { FormType } from "../../types"
import { postLead } from "../../api/leads"
import { getUniversalId } from "../../api/uinversal-id"
import useSiteMetadata from "../../hooks/use-site-metadata"
import lookUpState from "../../../utils/look-up-state"
import { trimmedGaCookieValue } from "../../../utils/look-up-ga-cookie"
import { trimmCookieValue } from "../../../utils/look-up-sbjs";

type FormProps = {
    form: FormType
    [key: string]: any
}

type FormData = {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    income: string
    state: string
    zipCode: string
    dateOfBirth: string
    clickDimensionsUserId: string
    clickDimensionsSessionId: string
    googleUserId: string
    webVisits: string
    webPageViews: string
    sourceType: string
    firstDate: string
    firstSource: string
    firstMedium: string
    firstCampaign: string
    landingPageFull: string
    sourceReferrer: string
}

const BrandedHybridLeadForm = ({ form, ...props }: FormProps) => {
    const search = props?.location?.search
    const parsedSearch = queryString.parse(search)
    const zipCodeProp = parsedSearch.zipCode ? `${parsedSearch.zipCode}` : props?.zipCode
    const { siteUrl, environment } = useSiteMetadata()
    const [stateData, setData] = useState({
        isSubmitting: false,
        zipCode: zipCodeProp ? `${zipCodeProp}` : ``,
        state: lookUpState(`${props?.zipCode || `0`}`),
    })
    const [clickDimensionsUserId, setclickDimensionsUserId] = useState('')
    const [clickDimensionsSessionId, setclickDimensionsSessionId] = useState('')
    const [googleUserId, setgoogleUserId] = useState('')
    const [webVisits, setwebVisits] = useState('')
    const [webPageViews, setwebPageViews] = useState('')
    const [sourceType, setsourceType] = useState('')
    const [firstDate, setfirstDate] = useState('')
    const [firstSource, setfirstSource] = useState('')
    const [firstMedium, setfirstMedium] = useState('')
    const [firstCampaign, setfirstCampaign] = useState('')
    const [landingPageFull, setlandingPageFull] = useState('')
    const [sourceReferrer, setsourceReferrer] = useState('')
    const [landingPage, setlandingPage] = useState('')

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm()
    const firstNameClasses = `block w-full border-gray-400 rounded-button bg-inputBackground ${errors.firstName ? `border-red-500` : ``}`
    const lastNameClasses = `block w-full border-gray-400 rounded-button bg-inputBackground ${errors.lastName ? `border-red-500` : ``}`
    const emailClasses = `block w-full md:col-span-2 border-gray-400 rounded-button bg-inputBackground ${errors.email ? `border-red-500` : ``}`
    const phoneNumberClasses = `block w-full border-gray-400 rounded-button bg-inputBackground ${errors.phoneNumber ? `border-red-500` : ``}`
    // const incomeClasses = `block w-full border-gray-400 rounded-button bg-inputBackground ${errors.income ? `border-red-500` : ``}`
    const stateClasses = `block w-full border-gray-400 rounded-button bg-inputBackground ${errors.state ? `border-red-500` : ``}`
    const zipCodeClasses = `block w-full border-gray-400 rounded-button bg-inputBackground ${errors.zipCode ? `border-red-500` : ``}`
    const buttonClasses = `p-2 w-1/2 lg:w-1/3 bg-background text-primary rounded-button border-brandYellow font-semibold border-2 ${stateData.isSubmitting ? `disabled` : `primary`}`

    const wrapGetUniversalId = async (data: FormData): Promise<string | undefined> => {
        try {
            const universalId = await getUniversalId({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phoneNumber: data.phoneNumber,
                income: '', //data.income,
                state: data.state,
                zipCode: data.zipCode,
                dateOfBirth: '', //data.dateOfBirth,
                familySize: 1,
                leadSourceDomain: siteUrl,
                environment: environment,
                clickDimensionsUserId: clickDimensionsUserId,
                clickDimensionsSessionId: clickDimensionsSessionId,
                googleUserId: googleUserId,
                webVisits: webVisits,
                webPageViews: webPageViews,
                sourceType: sourceType,
                firstDate: firstDate,
                firstSource: firstSource,
                firstMedium: firstMedium,
                firstCampaign: firstCampaign,
                landingPageFull: landingPageFull,
                sourceReferrer: sourceReferrer,
                landingPage: landingPage,
            })

            return universalId
        } catch (err) {
            // @ts-ignore
            window.datadogLogs?.logger.error(`submit universal id error:`, body)
        }

        return undefined
    }

    const onSubmit = async (data: FormData) => {
        setData({ ...stateData, isSubmitting: true })
        try {
            const result = await postLead({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phoneNumber: data.phoneNumber,
                income: '', // data.income,
                state: data.state,
                zipCode: data.zipCode,
                dateOfBirth: '', //data.dateOfBirth,
                familySize: 1,
                leadSourceDomain: siteUrl,
                environment: environment,
                clickDimensionsUserId: clickDimensionsUserId,
                clickDimensionsSessionId: clickDimensionsSessionId,
                googleUserId: googleUserId,
                webVisits: webVisits,
                webPageViews: webPageViews,
                sourceType: sourceType,
                firstDate: firstDate,
                firstSource: firstSource,
                firstMedium: firstMedium,
                firstCampaign: firstCampaign,
                landingPageFull: landingPageFull,
                sourceReferrer: sourceReferrer,
                landingPage: landingPage
            })

            if (result) {
                // First get a univeral id for this lead, so it can be tracked with analytics
                const universalId = await wrapGetUniversalId(data)

                // GA event logging
                if (typeof marketingDataLayer !== `undefined`) {
                    marketingDataLayer?.push({
                        universalID: universalId,
                    })

                    marketingDataLayer?.push({
                        event: `gaForm`,
                        eventCategory: `Form`,
                        eventAction: `lead-form-v2`,
                        eventLabel: location.pathname,
                    })
                }

                // Tealium event logging
                if (typeof utag !== `undefined`) {
                    console.log(`Tealium is loaded`)

                    utag.link({
                        customer_birthdate: "", //Required
                        customer_email: `${data.email}`, //Required
                        customer_first_name: `${data.firstName}`, //Required
                        customer_id: trimmedGaCookieValue(), //Required (RTRIM value of _ga Cookie)
                        customer_last_name: `${data.lastName}`, //Required
                        customer_name: "", //Required
                        customer_phone_number: `${data.phoneNumber}`, //Required
                        customer_postal_code: `${data.zipCode}`, //Required
                        customer_state: `${data.state}`, //Required
                        customer_type: "family", //Required
                        universal_id: universalId,
                        form_id: "", //Required
                        form_name: "Inquiry Form", //Required
                        tealium_event: "form_complete", //Required
                    })
                }

                location.assign(`${form.destination}`)
            }

            setData({ ...stateData, isSubmitting: false })
        } catch (err) {
            setData({ ...stateData, isSubmitting: false })
            alert(`Your request was not sent successfully.  ${err.message}`)
        }
    }

    // Configure form values and validation on form register
    React.useEffect(() => {
        //register(`dateOfBirth`, { required: true })

        const state = lookUpState(`${zipCodeProp || `0`}`)

        setData({
            ...stateData,
            state: state,
        })
        setValue(`state`, state)
    }, [register])

    function getPathFromUrl(url: string) {
        return url.split(/[?#]/)[0];
    }

    function date() {
        try {
            return new Date(Number(trimmCookieValue(`sbjs_first_add`, 0))).toISOString()
        } catch (error) {
            return new Date().toISOString()
        }
    }

    React.useEffect(() => {
        // test 
        // @ts-ignore
        setclickDimensionsUserId(document.cookie?.split(`; `)?.find(row => row.startsWith(`cuvid=`))?.split(`=`)[1]);
        // @ts-ignore
        setclickDimensionsSessionId(document.cookie?.split(`; `)?.find(row => row.startsWith(`cusid=`))?.split(`=`)[1]);
        // @ts-ignore
        setgoogleUserId(document.cookie?.split(`; `)?.find(row => row.startsWith(`_ga=`))?.split(`=`)[1]);
        // @ts-ignore
        setwebVisits(trimmCookieValue("sbjs_udata", 0));
        // @ts-ignore
        setwebPageViews(trimmCookieValue(`sbjs_session`, 0));
        // @ts-ignore
        setsourceType("Web Form");
        // @ts-ignore
        setfirstDate(date());
        // @ts-ignore
        setfirstSource(trimmCookieValue(`sbjs_first`, 1));
        // @ts-ignore
        setfirstMedium(trimmCookieValue(`sbjs_first`, 2));
        // @ts-ignore
        setfirstCampaign(trimmCookieValue(`sbjs_first`, 3));
        // @ts-ignore
        setlandingPageFull(trimmCookieValue(`sbjs_current_add`, 1));
        // @ts-ignore
        setsourceReferrer(trimmCookieValue(`sbjs_current_add`, 2));
        // @ts-ignore
        setlandingPage(getPathFromUrl(trimmCookieValue(`sbjs_current_add`, 1)));
    }, [])

    return (
        <form id="lead-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-2">
                <div>
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                        <span className="text-red-500">*</span> Primary Contact First Name 
                    </label>
                    <input {...register("firstName", { required: true })} type="text" className={firstNameClasses}
                        placeholder="First Name..." autoComplete="given-name" autoFocus />
                </div>

                <div>
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                        <span className="text-red-500">*</span> Primary Contact Last Name 
                    </label>
                    <input {...register("lastName", { required: true })} type="text" className={lastNameClasses}
                        placeholder="Last Name..." autoComplete="family-name" />
                </div>

                <div className="md:col-span-2">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                        <span className="text-red-500">*</span> Email 
                    </label>
                    <input {...register("email", { required: true })} type="email" className={emailClasses}
                        placeholder="Email..." autoComplete="email" />
                </div>

                
                <div className="md:col-span-2">
                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-1">
                        <span className="text-red-500">*</span> Phone 
                    </label>
                    <input {...register("phoneNumber", { required: true })} type="tel" className={phoneNumberClasses}
                        placeholder="Phone..." autoComplete="tel" />
                </div>

                {/* <div>
                    <label htmlFor="income" className="block text-sm font-medium text-gray-700 mb-1">
                        <span className="text-red-500">*</span> Income Household 
                    </label>
                    <input {...register("income", { required: true })} type="number" className={incomeClasses}
                        placeholder="Income Household..." />
                </div> */}

                <div className="grid grid-cols-2 md:col-span-2 gap-2">
                <div>
                    <label htmlFor="state" className="block text-sm font-medium text-gray-700 mb-1">
                        <span className="text-red-500">*</span> State
                    </label>
                    <select
                        {...register("state", { required: true })}
                    className={stateClasses}
                    autoComplete="address-level1"
                    value={stateData.state}
                    onChange={(e: { target: { value: any } }) => {
                        setData({ ...stateData, state: e.target.value })
                    }}
                >
                    <option value="" disabled className="text-gray-400">
                        Select a state...
                    </option>
                    <option value="AL">Alabama</option>
                    <option value="AR">Arkansas</option>
                    <option value="AZ">Arizona</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="IA">Iowa</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MD">Maryland</option>
                    <option value="ME">Maine</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MO">Missouri</option>
                    <option value="MS">Mississippi</option>
                    <option value="MT">Montana</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="NE">Nebraska</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NV">Nevada</option>
                    <option value="NY">New York</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VA">Virginia</option>
                    <option value="VT">Vermont</option>
                    <option value="WA">Washington</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WV">West Virginia</option>
                    <option value="WY">Wyoming</option>
                </select>
                </div>

                <div>
                    <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700 mb-1">
                        <span className="text-red-500">*</span> Zip Code 
                    </label>
                    <input
                        {...register("zipCode", { required: true, maxLength: 5, minLength: 4 })}
                        type="number"
                        className={zipCodeClasses}
                        placeholder="Zip Code..."
                        autoComplete="postal-code"
                        defaultValue={stateData.zipCode}
                        value={stateData.zipCode}
                        onChange={(e: { target: { value: any } }) => {
                            setData({ ...stateData, zipCode: e.target.value })
                            setValue('zipCode', e.target.value)
                        }}
                    />
                </div>
                </div>
            </div>

            <div className="block">
                <div className="mt-0 md:mt-6">
                    <div>
                        <label className="inline-flex items-top">
                            <span className="text-xs">
                                By clicking below, I agree to receive calls and text messages (including by autodialer and prerecorded message) regarding health coverage at my
                                phone number above. This consent does not require me to purchase anything.
                            </span>
                        </label>
                    </div>
                </div>
            </div>

            <div className="text-center mt-5">
                {Object.keys(errors).length > 0 && (
                    <div
                        className="bg-red-400 p-3 my-10 text-white rounded">{(errors.consent && Object.keys(errors).length === 1 && `Please accept the terms.`) || `Please fix errors in your form.`}</div>
                )}

                <button type="submit" className={buttonClasses} disabled={stateData.isSubmitting}>
                    {stateData.isSubmitting &&
                        <Spinner className="float-left h-7 w-5 text-black" size={5} color="black" />}
                    Submit
                </button>
            </div>
        </form>
    )

}

export default BrandedHybridLeadForm
