import React from "react"
import loadable from "@loadable/component"
import { FormType } from "../types"
import QuoteToolV2 from "./forms/quote-tool-v2"

const ZipForm = loadable(() => import("./forms/zip-form"))
const ZipForm2 = loadable(() => import("./forms/zip-form-2"))
const ZipFormV3 = loadable(() => import("./forms/zip-form-v3"))
const LeadForm = loadable(() => import("./forms/lead-form"))
const LeadFormv2 = loadable(() => import("./forms/lead-form-v2"))
const LeadFormv3 = loadable(() => import("./forms/lead-form-v3"))
const QuoteTool = loadable(() => import("./forms/quote-tool"))
const BrandedHybridModalForm = loadable(() => import("./forms/branded-hybrid-modal-form"))
const BrandedHybridModalForm2 = loadable(() => import("./forms/branded-hybrid-modal-form-2"))
const StraightToModalForm = loadable(() => import("./forms/straight-to-modal-form"))
const TestimonialVideoForm = loadable(() => import("./forms/testimonial-video"))
const UhsmMemberIntake = loadable(() => import("./forms/uhsm-member-intake"))

type LayoutProps = {
  form: FormType
  [key: string]: any
}

const Form = ({ form, ...props }: LayoutProps) => {
  if (!form?.templateId) {
    return <div>Please add a form template id.</div>
  }

  switch (form.templateId) {
    case `zip-form`:
      return <ZipForm form={form} {...props} />
    case `zip-form-2`:
      return <ZipForm2 form={form} {...props} />
    case `zip-form-v3`:
      return <ZipFormV3 form={form} {...props} />
    case `lead-form`:
      return <LeadForm form={form} {...props} />
    case `lead-form-v2`:
      return <LeadFormv2 form={form} {...props} />
    case `lead-form-v3`:
      return <LeadFormv3 form={form} {...props} />
    case `quote-tool`:
      return <QuoteTool form={form} {...props} />
    case `testimonial-video`:
      return <TestimonialVideoForm form={form} {...props} />
    case `uhsm-member-intake`:
      return <UhsmMemberIntake form={form} {...props} />
    case `quote-tool-v2`:
      return <QuoteToolV2 form={form} {...props} />
    case `branded-hybrid-modal-form`:
      return <BrandedHybridModalForm form={form} {...props} />
    case `branded-hybrid-modal-form-2`:
      return <BrandedHybridModalForm2 form={form} {...props} />
    case `straight-to-modal-form`:
      return <StraightToModalForm form={form} {...props} />
    default:
      return <ZipForm form={form} {...props} />
  }
}

export default Form
