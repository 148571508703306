import React from "react"
import loadable from "@loadable/component"
import { FooterType } from "../types"

const FooterDefault = loadable(() => import("./footers/footer-default"))
const FooterDefaultLogo = loadable(() => import("./footers/footer-logo"))
const FooterUHSM = loadable(() => import("./footers/footer-uhsm"))
const FooterCalicovered = loadable(() => import("./footers/footer-calicovered"))
const FooterMediaLanding = loadable(() => import("./footers/footer-media-landing"))
const FooterEnrollment = loadable(() => import("./footers/footer-enrollment"))
const FooterTwoSections = loadable(() => import("./footers/footer-two-sections-v3"))
const FooterModern = loadable(() => import("./footers/footer-modern"))
type LayoutProps = {
  footer: FooterType
  [key: string]: any
}

const Footer = ({ footer, ...props }: LayoutProps) => {
  switch (footer?.templateId) {
    case `uhsm`:
      return <FooterUHSM footer={footer} {...props} />
    case `calicoverage`:
      return <FooterCalicovered footer={footer} {...props} />
    case `footer-media-landing`:
      return <FooterMediaLanding footer={footer} {...props} />
    case `default-logo`:
      return <FooterDefaultLogo footer={footer} {...props} />
    case `annualopenenrollment-logo`:
      return <FooterEnrollment footer={footer} {...props} />
    case `two-sections-v3`:
      return <FooterTwoSections footer={footer} {...props} />
    case `footer-modern`:
      return <FooterModern footer={footer} {...props} />
    case `empty`: 
      return null;
    default:
      return <FooterDefault footer={footer} {...props} />
  }
}

export default Footer
